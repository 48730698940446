<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { orderStateList, orderStateList3 } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { farmingExperienceMixin } from '@/mixins/orderProcessing/farmingExperienceMixin'

export default {
  name: 'farmingExperience',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mixins:[farmingExperienceMixin],
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/farming/order/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '出售商品',
          type: 'input',
          key: 'productName',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'buyPhone',
          placeholder: '请输入手机号',
        },
      ]
    },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    // 退款事件
    onRefund({ records, item }) {
      let that = this
      // type手动传参 告诉当前订单类型
      orderConfig.refund({
        data: records,
        params: { data: { ...records, type: 'eatDrink' } },
        executeFun: [
          // 基础
          orderConfig.base,
          // 优惠券
          orderConfig.coupons,
          // 售后
          orderConfig.afterProcessing,
          // 记录
          orderConfig.record,
        ],
        success({ data, setHidden }) {
          api.order.refund
            .call(that, {
              url: '/farming/order/refund',
              params: { ...item, orderId: item.orderId, ...data },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      let that = this
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          align: 'left',
          isId: true,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            if (records.refundAmount) {
              return (
                <div>
                  <span>{records.payAmount.toFixed(2)}</span>
                  <span>（{records.refundAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return <div>{records.payAmount.toFixed(2)}</div>
            }
          },
        },
        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
        },
        {
          dataIndex: 'buyPhone',
          title: '手机号',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '下单时间',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          type: 'badge',
          filters: orderStateList3,
          filterMultiple: false,
          render(data, records) {
            return {
              // showDot: true,
              name: records.refundId ? '待退款' : (orderStateList3.find((e) => e.value == data) || { text: '' }).text,
              color: records.refundId ? 'red' : 'transparent',
            }
          },
          onExport: (text) => (orderStateList3.find((e) => e.value == text) || { text: '' }).text,
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          customRender: function customRender(text, records) {
            return (
              <div class="actionBox">
                {records.orderItemList.map((e, index) => {
                  // 获取对应button按钮 子订单需要判断是否有退款
                  let data = that.getColumnsButtonData({
                    parent: records,
                    item: e,
                    index,
                    childrenId: e.id,
                  })
                  return (
                    <div class="product-order-item">
                      {data.map((el) => that.renderColumnButton({ orderItem: e, item: el }))}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
      ]
    },
    getColumnsButtonData({ parent, item, index, childrenId } = {}) {
      let arr = [
        {
          name: '开票信息',
          display:  parent.invoiceStatus && parent.invoiceStatus != 0,
          onClick: () => this.onInvoice(parent),
        },
        {
          name: '去处理',
          display: parent.refundId,
          onClick: () => this.$router.push(`/orderProcessing/refund?id=${parent.refundId}`),
        },
        {
          name: '查看订单',
          display: true,
          onClick: () => {
            // 混入
            this.onRowSelectFarmingExperience(parent.id, item.id)
          },
        },
        {
          type: 'pop',
          popTitle: '确认关闭吗?',
          name: '关闭订单',
          display: parent.status == '0',
          onClick: () => this.onBatchDelete([parent]),
        },
      ]
      if (process.env.NODE_ENV === 'development') {
        return arr.filter((e) => e.display)
      } else {
        const buttonName = apiTool.getButtonName()
        return arr.filter((e) => e.display && buttonName.some((el) => e.name.indexOf(el) !== -1))
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },
       // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    //开票信息
    onInvoice(records) {
      const ext = [
        // 基本信息
        orderConfig.invoices,
        // 接收信息
        orderConfig.invoicesAccept,
      ]
      this.getInvoiceDetailData(records.id).then((e) => {
        orderConfig.open({
          title: '开票信息',
          data: e,
          params: { data: e },
          executeFun: ext,
        })
      })
    },
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.actionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
